module.exports = {
  
    //baseUrl: "http://localhost:2053/",

    // baseUrl: "http://45.33.70.165:2053/",

     baseUrl: "https://filipcoin.com/",

     adminethaddress : "0x49E14e54609075D394FdC0df82b323CF60018f9d", //live

   //  adminethaddress : "0xdC6A32D60002274A16A4C1E93784429D4F7D1C6a",
  
    network : "56",

  // network : "5",

   secretOrKey : "3TAXElQb9UnwkBv1RFb47w==",              //"flipcoin2021",
 
};


//https://testnet.bscscan.com/address/0xa68f381e213adf5b5df62f4bff39d4aa86b4d053#code